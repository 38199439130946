/**
 * @author Tommy Brière
 * @copyright (c) 2021 Cube Noir
 * @fileoverview Show the loading screen
 * */

import React from "react";

const LoadingOverlay = () => {
  return (
    <div className="chargement-global">
      <div className="wrapper">
        <p className="screen-reader-only">Chargement / Loading</p>
        <img className="loading-img" src={require("../../assets/images/roue-chargement.png")} alt="" />
      </div>
    </div>
  );
};

export default LoadingOverlay;
