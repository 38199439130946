import { useContext } from "react";
import { PersistedDataContext } from "./PersistedDataContext";

export const useAppSelector = (fn, ...rest) => {
  const state = useContext(PersistedDataContext);
  return fn(state, ...rest);
};

export const useAppDispatch = () => {
  const state = useContext(PersistedDataContext);
  return state.dispatch;
};
