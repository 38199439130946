import { createTheme } from "@material-ui/core/styles";

import palette from "./palette";
import overrides from "./overrides";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  // @ts-ignore
  palette,
  // @ts-ignore
  overrides
});

export default theme;
