import { useCallback, useContext, useEffect, useMemo } from "react";
import { prefetch, useRouteData } from "react-static";
import { useLocation } from "@reach/router";

import { NavigationContext } from "../components/TransitionRoot/NavigationContext";
import { collectCinematic, selectCinematics } from "../components/PersistedData/cinematicsSlice";
import { collectObject, selectCollectables } from "../components/PersistedData/collectablesSlice";
import { useCurrentSettings } from "../components/PersistedData/settingsSlice";
import { collectPlace, selectPlaces } from "../components/PersistedData/placesSlice";
import { LocationState, setSavedLocation, useSavedLocation } from "../components/PersistedData/locationSlice";
import {
  MEDIA_ROOT,
  USE_OPTMISED_MEDIA,
  startingLocation,
  preventDirectAccess,
  WEBSITE_SITE_ROOT,
  WEBSITE_BASE_PATH,
  RELOAD_AFTER_XWGL
} from "./config";

import type {
  LocationType,
  LocationLink,
  WithPrevState,
  Cinematic,
  Place,
  CollectableObject,
  Preset,
  MediaTransform
} from "./data";
import { useAppDispatch, useAppSelector } from "../components/PersistedData/hooks";
import { mediaPresets } from "../data/mediaPresets";

/* eslint react-hooks/rules-of-hooks: "off" */
/* eslint react-hooks/exhaustive-deps: "off" */

let webpSupported = false;

function checkIfWebpSupported() {
  // If the browser doesn't has the method createImageBitmap, you can't display webp format
  if (typeof window === "undefined" || !window.createImageBitmap) return false;

  const elem: HTMLCanvasElement = document.createElement("canvas") as any as HTMLCanvasElement;
  if (elem.getContext && elem.getContext("2d")) {
    elem.width = 4;
    elem.height = 4;
    const wp = elem.toDataURL("image/webp");
    webpSupported = wp && wp.indexOf("data:image/webp") === 0;
  }
}

export const trimLastSlash = (path: string) => {
  const len = path.length;
  if (path && path !== "" && path.substring(len - 1) === "/") {
    return path.substring(0, len - 1);
  }
  return path;
};

export const trimFirstSlash = (path: string) => {
  if (path && path !== "" && path.substring(0, 1) === "/") {
    return path.substring(1);
  }
  return path;
};

function addMediaSufix(prefix: string, spec: { width; height; method; type }) {
  const { width, height, method, type } = spec;
  const tini = type === "png";
  return `${prefix}.${method}_${width}_${height}_${tini ? "t" : "s"}.${type}`;
}

const getOptimisedImg = (path: string, presetName?: Preset): string => {
  const transform: MediaTransform = mediaPresets[presetName];

  switch (transform?.type) {
    case "jpeg":
      return addMediaSufix(path, { ...transform, type: webpSupported ? "webp" : "jpeg" });
    case "png":
      return addMediaSufix(path, { ...transform, type: webpSupported ? "webp" : "png" });
  }

  // Not transformed
  return path;
};

export const getMediaUrl = (path: string, presetName?: Preset): string => {
  if (!path) {
    return "";
  }
  if (USE_OPTMISED_MEDIA) {
    path = getOptimisedImg(path, presetName);
  }
  const mediaRoot = MEDIA_ROOT || "https://dev-static-souvenance.storage.googleapis.com/medias";
  return `${trimLastSlash(mediaRoot)}/${trimFirstSlash(path)}`;
};

export interface GetUrlFunction {
  (lang: string, type: LocationType, id?: string, sub?: string | number): string;
}

const currentLangBasePath = "/";
export const getGetUrl = ({ collectables, places, cinematics, urls }): GetUrlFunction => {
  const getPlace = (id) => {
    return places.find((p) => {
      return p.id === id;
    });
  };

  return (lang, type: LocationType, id?, sub?) => {
    if (type === "home") {
      return currentLangBasePath;
    }

    if (id) {
      switch (type) {
        case "cinematic":
          const cinematic: Cinematic = cinematics.find((c) => c.id === id);
          const p2: Place = getPlace(cinematic.next.id);
          return `${currentLangBasePath}${p2[lang].url || p2.id}/${cinematic[lang].url || cinematic.id}`;
        case "collectable":
          const collectable: CollectableObject = collectables.find((c) => c.id === id);
          const p3: Place = getPlace(collectable.next.id);
          return `${currentLangBasePath}${p3[lang].url || p3.id}/${collectable[lang].url || collectable.id}`;
        case "place":
          const place: Place = getPlace(id);
          if (typeof sub === "number" || typeof sub === "string") {
            return `${currentLangBasePath}${place[lang].url || place.id}/${sub}`;
          }
          return `${currentLangBasePath}${place[lang].url || place.id}`;
        default:
          if (typeof sub === "number" || typeof sub === "string") {
            return `${currentLangBasePath}${type}/${id}/${sub}`;
          }
          return `${currentLangBasePath}${type}/${id}`;
      }
    }
    const loc = urls[lang];
    return `${currentLangBasePath}${loc[type]}`;
  };
};

export const useGetUrl = (): GetUrlFunction => {
  const { collectables, places, cinematics, urls } = useRouteData();
  const fn = getGetUrl({ collectables, places, cinematics, urls });
  const getUrl = useCallback(fn, [collectables, places, cinematics, urls]);
  return getUrl;
};

export const useHomeUrl = () => {
  return useUrl({ type: "home" });
};

export const useLang = () => {
  let { lang } = useRouteData();
  const l: LocationState = useSavedLocation();
  if (!lang) {
    lang = l.lang || "fr";
  }
  return lang;
};

export interface UseUrlParams {
  type: LocationType;
  id?: string;
  sub?: string | number;
}

export const preload = (url): Promise<void> => {
  // template preload is broken, so preload only templates
  return prefetch(url, { type: "data" });
};

export const useUrl = ({ type, id, sub }: UseUrlParams) => {
  const get = useGetUrl();
  const lang = useLang();
  const url = get(lang, type, id, sub);
  preload(url);
  return url;
};

export const useSavedLocationUrl = () => {
  const l: LocationState = useSavedLocation();
  return useUrl(l);
};

/**
 * Obtient des informations sur l'url précédente
 * N'attache pas de gestionnaire de touche
 */
export const usePrevUrl = (def?: UseUrlParams) => {
  const savedLocation = useSavedLocation();
  const location = useLocation();

  const state: WithPrevState = location?.state;
  const prevState = state?.prevState;
  let p: string;
  try {
    p = useUrl(prevState?.location || def || savedLocation);
  } catch (err) {
    p = useHomeUrl();
  }
  const url = useMemo(() => {
    return {
      path: p,
      state: prevState?.state
    };
  }, [p, prevState?.state]);
  return url;
};

/**
 * Retourne les informations nécessaire pour retourner à la page précédente
 * Installe un gestionnaire de touche pour gérer la touche escape
 */
export const usePrevLink = (def?: UseUrlParams) => {
  const url = usePrevUrl(def);
  const { goto } = useContext(NavigationContext);
  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        goto(url.path, url.state);
      }
    };

    document.addEventListener("keydown", keydown);
    return () => {
      document.removeEventListener("keydown", keydown);
    };
  }, []);
  return url;
};

/**
 * Génération d'un état utilisation pour indiquer comment naviguer à cette page
 */
export const useReturnToSelfState = (): WithPrevState => {
  const location = useLocation();
  const current = useRouteData();
  const { lang, type, id } = current;
  return useMemo(() => {
    const goBackState: WithPrevState = {
      prevState: {
        location: { lang, type, id },
        state: location ? location.state : null
      }
    };
    return goBackState;
  }, [lang, type, id, location?.state]);
};

export const useCollected = (type: LocationType): string[] => {
  if (typeof document === "undefined") {
    return [];
  }
  switch (type) {
    case "place":
      return useAppSelector(selectPlaces);
      break;
    case "cinematic":
      return useAppSelector(selectCinematics);
      break;
    // case "collectable":
    default:
      return useAppSelector(selectCollectables);
      break;
  }
};

let recordCallback: ({ type, id }) => void = () => {};
export function setRecordCallBack(callback) {
  recordCallback = callback;
}

export const useRecordCurrentLocation = () => {
  if (typeof document === "undefined") {
    return;
  }
  const settings = useCurrentSettings();
  const location = useLocation();
  const state: WithPrevState = location?.state;
  const current = useRouteData();
  const { lang, type, id, item } = current;
  const dispatch = useAppDispatch();
  const saved: LocationState = useSavedLocation();
  const { goto } = useContext(NavigationContext);
  const collecteds = useCollected(type);
  const cineUrl = useUrl({ type: "cinematic", id: item?.cinematic });
  recordCallback(current);
  useEffect(() => {
    if (settings.mode === "noscript") {
      return;
    }
    if (lang !== saved.lang || type !== saved.type || id !== saved.id) {
      if (lang && type && id) {
        if (collecteds.indexOf(id) >= 0) {
          if (!state?.prevState) {
            dispatch(setSavedLocation(current));
          }
        } else if (preventDirectAccess && type === "place") {
          goto(cineUrl);
        }
      }
    }
  }, [current]);
};

export const useCollect = ({ type, id }: LocationLink) => {
  const dispatch = useAppDispatch();
  const url = useUrl({ type, id });

  return useCallback(() => {
    switch (type) {
      case "place":
        dispatch(collectPlace(id));
        break;
      case "cinematic":
        dispatch(collectCinematic(id));
        break;
      case "collectable":
        dispatch(collectObject(id));
        break;
    }
    return url;
  }, [type, id]);
};

export const useCollectStartingLocation = () => {
  return useCollect(startingLocation);
};

export const isBrowser = typeof document !== "undefined";

export const isIOS = isBrowser && /iPad|iPhone|iPod/.test(navigator?.userAgent);
let wglUsage = 0;
/**
 * Détermine si on doit recharger la page
 *
 * @param reloadPriority
 * @returns
 */
export const webGLLeakCrashFix = (reloadPriority: number) => {
  const limit = RELOAD_AFTER_XWGL - reloadPriority * 10;
  console.log("webGLLeakCrashFix", wglUsage, limit);
  if (isIOS || wglUsage >= limit) {
    return true;
  }
  return false;
};

/**
 * Incrémente le compteur de wbgl affiché
 */
export const webGLShown = () => {
  wglUsage++;
};

export const useNbNewLocation = () => {
  const settings = useCurrentSettings();
  const enablePersistance = settings.mode !== "noscript";
  const cinematicsSeens = isBrowser ? useAppSelector(selectCinematics) : [];
  const placesUnlocked = isBrowser ? useAppSelector(selectPlaces) : [];
  const nbNewPlace = enablePersistance ? placesUnlocked.length - cinematicsSeens.length : 0;
  return nbNewPlace;
};

export const absUrl = (url) => {
  return url.indexOf(WEBSITE_SITE_ROOT) === 0 ? url : `${WEBSITE_SITE_ROOT}${url}`;
};

checkIfWebpSupported();
