/*
 * @author Tommy Brière
 * @copyright (c) 2017 Groupe PVP
 * @fileoverview TODO
 */

/* eslint @typescript-eslint/no-this-alias: "off" */
/* eslint func-names: "off" */

import Logger from "./Logger";
import Signal from "./Signal";

const logger: Logger = Logger.get("VideoPlayer");

const LOG_TO_CONSOLE = false;
const TIMEOUT = 20 * 1000;
const SEEK_TIMEOUT = 1000;
const INIT_TIMEOUT = 1000;

export default function VideoPlayer() {
  /**
   * Certains navigateurs ne se comportent pas correctement
   * Lorsque on change l'état du vidéo associé en succession rapide
   * On va donc garder l'état supposé du vidéo
   * Pour ajuster le vidéo
   *
   */
  const SEEKING = {
    name: "SEEKING",
    seeked() {
      setEtat(PAUSED);
      update();
    },
    timeout() {
      // If timeout, just make as if it succeeded
      setEtat(PAUSED);
      update();
    }
  };
  const PAUSED = {
    name: "PAUSED",
    play() {
      callPlay();
      setEtat(LOADING);
      update();
    },
    seek() {
      seekTo();
      setEtat(SEEKING);
      regTimeout(SEEK_TIMEOUT);
      update();
    },
    changed: changeSrc
  };
  const PLAYING = {
    name: "PLAYING",
    pause() {
      video.pause();
      setEtat(PAUSED);
      update();
    },
    seek() {
      video.pause();
      setEtat(PAUSED);
      update();
    },
    changed: changeSrc
  };
  const LOADING = {
    name: "LOADING",
    timeout() {
      logger.warning(`timeout sur play ${video.src}`);
      setEtat(ERROR);
    },
    time() {
      setEtat(PLAYING);
      update();
    },
    error() {
      logger.warning(`Impossible de lire ${video.src}`);
      setEtat(ERROR);
    }
  };
  const ERROR = {
    name: "ERROR",
    changed: changeSrc,
    time() {
      setEtat(PLAYING);
      update();
    }
  };
  const IDLE = {
    name: "IDLE",
    changed: changeSrc
  };
  const REQUIRE_CLICK = {
    name: "REQUIRE_CLICK",
    time() {
      setEtat(PLAYING);
      update();
    },
    timeout() {
      logger.warning(`timeout sur play ${video.src}`);
      setEtat(ERROR);
    },
    click() {
      const src = video.src || current;
      if (src) {
        changeSrc(src);
        setEtat(REQUIRE_CLICK);
      } else {
        logger.error("REQUIRE_CLICK.click pas de vidéo à jouer ! On à besoin d'un vidéo pour initialiser le composant");
      }
    }
  };
  const TESTING = {
    name: "TESTING",
    timeout() {
      // may require click
      setEtat(REQUIRE_CLICK);
      me.onInitComplete.trigger();
    },
    time() {
      logger.debug("TESTING", "got time");
      setEtat(IDLE);
      me.onInitComplete.trigger();
      update();
    },
    error() {
      // got erreor checking if we can autoplay
      // try to autoplay, may work
      setEtat(REQUIRE_CLICK);
      me.onInitComplete.trigger();
      update();
    },
    click() {
      const src = video.src || current;
      if (src) {
        changeSrc(src);
        setEtat(REQUIRE_CLICK);
      } else {
        logger.error("REQUIRE_CLICK.click pas de vidéo à jouer ! On à besoin d'un vidéo pour initialiser le composant");
      }
    }
  };
  let UNDEF;
  const MISSING_FUNC = {};

  let me = this;
  let current;
  let next;
  let playing;
  let currentTime;
  let currentSrc;

  let etat;
  let timeout;
  let video;
  let lastEvent = null;

  function seekTo() {
    if (UNDEF !== currentTime) {
      video.currentTime = currentTime;
      // $g.log("seek to", currentTime);
      currentTime = UNDEF;
    }
  }

  function setSrc(targetSrc) {
    currentSrc = targetSrc;
    video.src = targetSrc;
  }

  function changeSrc(targetSrc) {
    const isCurrent = targetSrc === current;
    setSrc(targetSrc);
    if (isCurrent) {
      seekTo();
    }
    callPlay();
    setEtat(LOADING);
    regTimeout(TIMEOUT);
  }

  function regTimeout(time) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      event("timeout");
    }, time);
  }

  function videoEnded() {
    event("ended");
  }

  function videoError(e) {
    logger.warning("impossible de jouer le video", video.src, e);
    const r = event("error");
    if (r === MISSING_FUNC) {
      logger.warning("Erreur non géré", e);
      setEtat(ERROR);
    }
  }

  function videoTime() {
    if (video.currentTime > 0) {
      // $g.log(e);
      event("time");
    }
  }

  function videoPlay() {
    // event("time");
    // event("playing");
  }

  function videoSeeked() {
    if (LOG_TO_CONSOLE) {
      logger.log("seeked");
    }
    event("seeked");
  }

  function attachEvents() {
    video.addEventListener("timeupdate", videoTime);
    video.addEventListener("play", videoPlay);
    video.addEventListener("ended", videoEnded);
    video.addEventListener("error", videoError);
    video.addEventListener("seeked", videoSeeked);
  }

  function removeEvents() {
    video.removeEventListener("timeupdate", videoTime);
    video.removeEventListener("play", videoPlay);
    video.removeEventListener("ended", videoEnded);
    video.removeEventListener("error", videoError);
    video.removeEventListener("seeked", videoSeeked);
  }

  function setEtat(newEtat) {
    // event("exit");
    lastEvent = null;
    etat = newEtat;
    if (LOG_TO_CONSOLE) {
      logger.debug("setEtat", newEtat);
    }
    // event("enter");
    me.onStateChange.trigger(etat);
  }

  function event(evt, data?) {
    let r = MISSING_FUNC;
    const f = etat ? etat[evt] : null;
    if (evt !== lastEvent) {
      if (LOG_TO_CONSOLE) {
        logger.debug("event", etat, evt, data);
      }
      lastEvent = evt;
    }
    if (f) {
      r = f(data);
    }
    return r;
  }

  function callPlay(noRetry?: boolean) {
    const p = video.play();
    if (p && p.then) {
      p.then(
        () => {
          // make sure we get events
          event("time");
          event("playing");
        },
        (e) => {
          logger.debug(e);
          if (e === "NotSupportedError") {
            videoError(e);
          } else if (etat === LOADING && !noRetry) {
            // autofix bug
            callPlay(true);
          }
        }
      ); // Catch play rejected error
    }
  }

  function videoSrc() {
    return currentSrc;
  }

  function update() {
    const targetSrc = current || next;
    const shouldPlay = current && playing;
    const srcChanged = targetSrc && videoSrc() !== targetSrc;
    if (srcChanged) {
      event("changed", targetSrc);
    } else if (currentTime !== UNDEF) {
      event("seek");
    } else if (shouldPlay) {
      event("play");
    } else {
      event("pause");
    }
  }

  /**
   * play the current music
   * @returns {undefined}
   */
  this.play = function () {
    playing = true;
    update();
  };

  /**
   * Sto playing the current music
   * @returns {undefined}
   */
  this.stop = function () {
    playing = false;
    update();
  };

  Object.defineProperty(this, "playing", {
    get() {
      return playing;
    },
    set(value) {
      playing = value;
      update();
    }
  });
  Object.defineProperty(this, "current", {
    get() {
      return current;
    },
    set(value) {
      current = value;
      update();
    }
  });
  Object.defineProperty(this, "next", {
    get() {
      return next;
    },
    set(value) {
      next = value;
      update();
    }
  });
  Object.defineProperty(this, "currentTime", {
    get() {
      if (me.ready) {
        if (currentTime !== UNDEF) {
          return currentTime;
        }
        return video.currentTime * 1000;
      }
      return -1;
    },
    set(value) {
      if (typeof value === "number") {
        currentTime = value / 1000;
      } else {
        logger.info("currentTime.set", value, " is not a number");
        currentTime = UNDEF;
      }
      update();
    }
  });
  Object.defineProperty(this, "duration", {
    get(): number {
      if (etat === PAUSED || etat === PLAYING) {
        return video.duration * 1000;
      }
      return NaN;
    }
  });
  Object.defineProperty(this, "ready", {
    get(): boolean {
      if (videoSrc() === current) {
        return etat === PLAYING || etat === PAUSED;
      }
      return false;
    }
  });
  Object.defineProperty(this, "error", {
    get() {
      if (etat === ERROR) {
        return video.error ? video.error.code : true;
      }
      return false;
    }
  });
  Object.defineProperty(this, "state", {
    get() {
      return etat ? etat.name : null;
    }
  });

  /**
   * Inialisation du composant
   * @param {options} Object  options à l'inialisation
   *  video       ne pas créer de vidéo réutilisé l'élément vidéo passé en paramètre
   *  click       indique que le vidéo passé à déjà passé le test du click
   *  videoUrl    fichier vidéo utilisable pour tester le playback
   *  muted       indique si il faut mettre l'attribut muted lorsque la balise vidéo doit être créé
   *              utilisé uniquement lorsque video n'est pas passé en paramètre
   */
  this.init = function (options) {
    if (LOG_TO_CONSOLE) {
      logger.log("INIT", options);
    }
    // var video = options.video;
    const { videoUrl, muted, click, crossorigin, loop } = options;
    if (options.video) {
      video = options.video;
    } else {
      video = document.createElement("Video");
      video.setAttribute("autobuffer", true);
      if (muted) {
        video.setAttribute("muted", true);
        video.muted = true;
      }
      if (crossorigin) {
        video.setAttribute("crossorigin", "anonymous");
      }
      if (loop) {
        video.setAttribute("loop", true);
      }
      video.setAttribute("playsinline", true);
      video.setAttribute("webkit-playsinline", true);
    }
    if (click) {
      logger.debug("pre clicked video");
      etat = IDLE; // Click test terminé on est prèt à jouré
      me.onInitComplete.trigger();
    } else if (videoUrl) {
      etat = TESTING;
      setSrc(videoUrl);
      // video.src = ;
      callPlay();
      regTimeout(INIT_TIMEOUT);
    } else {
      etat = REQUIRE_CLICK; // Click à faire
      me.onInitComplete.trigger();
    }
    me.video = video;
    attachEvents();
  };

  /**
   * Appelez cette méthode pour propager une événement de clic dans le composant
   * Cela va démarrer les vidéos qui ont besoin d'un click
   * @returns {undefined}
   */
  this.clicked = function () {
    event("click");
  };

  /**
   * Retourne true si on est dans l'état *click nécessaire*
   * @returns {boolean}
   */
  this.requireClick = function () {
    return etat === REQUIRE_CLICK;
  };

  /**
   * Nettoyage du composant
   */
  this.destroy = function () {
    if (video) {
      removeEvents();
      video.pause();
      video = null;
    }
    clearTimeout(timeout);
    timeout = null;
  };

  this.forceRequireClick = function () {
    etat = REQUIRE_CLICK;
  };

  this.onInitComplete = new Signal();
  this.onStateChange = new Signal();

  this.waitForReady = function (f) {
    function stateChange() {
      logger.log("waitForReady state change");
      if (etat && etat !== REQUIRE_CLICK && etat !== LOADING && etat !== TESTING) {
        me.onStateChange.unbind(stateChange);
        f();
        logger.log("waitForReady done");
      }
    }

    logger.log("waitForReady ", f);
    if (f) {
      me.onStateChange.bind(stateChange);
      stateChange();
    }
  };
}
