import { PersistedDataPersisted } from "./index.interface";

export function createAction<T, P>(name, fn: (state: Readonly<T>, payload: P) => Partial<T>) {
  return (payload: P) => {
    return (state: PersistedDataPersisted) => {
      const ls: T = state[name];
      const r: Partial<T> = fn(ls, payload);
      return {
        [name]: {
          ...ls,
          ...r
        }
      };
    };
  };
}
