export enum TransitionState {
  Static,
  Idle,
  EnteringLoading,
  Loading,
  ExitingLoading,
  EnteringScreen,
  ExitingScreen,
  InfiniteLoading
}
