export const i18nOptions = {
  resources: {},
  fallbackLng: "fr",
  nonExplicitSupportedLngs: true,
  lowerCaseLng: true,
  cleanCode: true,
  supportedLngs: ["fr", "en"],
  preload: ["fr", "en"],
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  detection: {
    order: ["path"]
  }
};
