import React from "react";

import Seo from "@components/Seo";
import InitInteractivity from "@components/InitInteractivity";
import { PersistedData } from "@components/PersistedData";
import CookieConsent from "@components/CookieConsent";
import { I18nProvider } from "./I18nextProvider";

const WrappedApp = ({ children }) => {
  if (typeof document !== "undefined") {
    return (
      <PersistedData>
        <I18nProvider>
          <InitInteractivity />
          <Seo />
          {children}
          <CookieConsent />
        </I18nProvider>
      </PersistedData>
    );
  }

  return (
    <PersistedData>
      <I18nProvider>
        <Seo />
        {children}
      </I18nProvider>
    </PersistedData>
  );
};

export default WrappedApp;
