/** *
 * Tommy Brière
 * Ébauche du composant de gestion de transition
 * N'est pas terminé ni utilisé actuellement
 */

import React, { useState, useEffect, useContext, useCallback } from "react";
import { navigate } from "@reach/router";
import LoadingOverlay from "../LoadingOverlay";
import { preload } from "../../utils/navigation";
import { TransitionState } from "./index.interface";
import { NavigationContext } from "./NavigationContext";

export const Loading = () => {
  const { incLoadingCounter, decLoadingCounter } = useContext(NavigationContext);
  useEffect(() => {
    incLoadingCounter();
    return () => {
      decLoadingCounter();
    };
  }, [decLoadingCounter, incLoadingCounter]);

  return <></>;
};

export const TransitionRoot = ({ children }) => {
  const [transitionState, setTransitionState] = useState(TransitionState.Static);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [target, setTarget] = useState<[string, any, any]>(null);

  useEffect(() => {
    const doBgPreload = async (path) => {
      incLoadingCounter();
      await preload(path);
      decLoadingCounter();
    };

    switch (transitionState) {
      case TransitionState.Static:
      case TransitionState.Idle:
        if (loadingCounter > 0) {
          setTransitionState(TransitionState.Loading);
        } else if (target) {
          doBgPreload(target[0]);
          setTransitionState(TransitionState.Loading);
        }
        break;
      case TransitionState.Loading:
        if (loadingCounter <= 0) {
          if (target) {
            if (target[1]) {
              navigate(target[0], target[1]);
            } else {
              navigate(target[0]);
            }
            setTarget(null);
            if (target[2]) {
              target[2]();
            }
          } else {
            setTransitionState(TransitionState.Idle);
          }
        }
        break;
    }
  }, [transitionState, loadingCounter, target]);

  const goto = useCallback(async (path: string, state?: any): Promise<any> => {
    return new Promise((resolve) => {
      if (target && target[2]) {
        target[2]();
      }
      setTarget([path, state, resolve]);
    });
  }, []);
  const incLoadingCounter = useCallback(() => {
    setLoadingCounter((value) => {
      return value + 1;
    });
  }, []);
  const decLoadingCounter = useCallback(() => {
    // Settimeout prevent flickers
    setTimeout(() => {
      setLoadingCounter((value) => {
        return value - 1;
      });
    }, 100);
  }, []);
  const instantEternalLoading = useCallback(() => {
    setLoadingCounter(10);
    setTransitionState(TransitionState.InfiniteLoading);
  }, []);

  return (
    <NavigationContext.Provider
      value={{ goto, incLoadingCounter, decLoadingCounter, transitionState, instantEternalLoading }}
    >
      {children}
      {transitionState === TransitionState.InfiniteLoading && <LoadingOverlay />}
    </NavigationContext.Provider>
  );
};
