/**
 * @author Tommy Brière
 * @copyright (c) 2017 Groupe PVP
 * @fileoverview Simple event system. Component can bind on this class to receive events
 * */

import Logger from "./Logger";

const logger = Logger.getLogger("Signal");

export default class Signal {
  private events = [];

  /**
   * Add a function to call at each trigger
   * @param me object     obtional instance
   * @param f function    function
   * @return undefined
   */
  bind = (m, f?) => {
    this.events.push(f ? [m, f] : [null, m]);
  };

  hasEvents = () => {
    return this.events.length > 0;
  };

  once = (f) => {
    const exec = (...args) => {
      this.unbind(exec);
      f(...args);
    };
    this.bind(exec);
  };

  /**
   * Remove a function added with bind
   * @param m object     obtional instance
   * @param f function
   * @return undefined
   */
  unbind = (m, f?) => {
    if (!f) {
      f = m;
      m = null;
    }
    let i = 0;
    while (i < this.events.length) {
      if (this.events[i][1] === f && this.events[i][0] === m) {
        this.events.splice(i, 1);
      } else {
        i += 1;
      }
    }
  };

  /**
   * Call all binded functions
   * @return undefined
   */
  trigger = (...args) => {
    const eve = this.events.slice();
    if (eve) {
      for (let i = 0; i < eve.length; i += 1) {
        try {
          const e = eve[i];
          e[1].apply(e[0], args);
        } catch (ex) {
          logger.exception(ex);
        }
      }
    }
  };

  unbindAll = () => {
    this.events = [];
  };
}
