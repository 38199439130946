import { Loading } from "@components/TransitionRoot";
import { TransitionState } from "@components/TransitionRoot/index.interface";
import { getInteractivityState, InteractivityState, loadFramework, startInteractivity } from "@utils/interactivity";
import React, { useContext, useEffect, useState } from "react";
import { useCurrentSettings } from "../PersistedData/settingsSlice";
import { NavigationContext } from "../TransitionRoot/NavigationContext";

const INIT_DELAY = 1000;

/**
 * Initialiser l'intéractivité d'avance pour qu'un click ne soit pas nécessaire par la suite
 */
const InitInteractivity = () => {
  const settings = useCurrentSettings();
  const { transitionState } = useContext(NavigationContext);
  const shouldStartFramework = settings.mode === "full";
  const [testing, setTesting] = useState(false);

  useEffect(() => {
    if (shouldStartFramework) {
      setTimeout(bootFramework, INIT_DELAY);
      document.addEventListener("click", start);
      document.addEventListener("touchstart", start);
      document.addEventListener("touchend", start);
      return () => {
        document.removeEventListener("click", start);
        document.removeEventListener("touchstart", start);
        document.removeEventListener("touchend", start);
      };
    }
  }, [shouldStartFramework]);

  const bootFramework = async () => {
    await loadFramework();
  };

  const start = () => {
    startInteractivity().then(() => {
      setTesting(false);
    });
    if (getInteractivityState() === InteractivityState.TESTING) {
      setTesting(true);
    }
  };

  return transitionState !== TransitionState.Idle && testing ? <Loading /> : <></>;
};

export default InitInteractivity;
