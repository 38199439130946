import palette from "../palette";

export default {
  outlined: {
    "&:hover": {
      color: "#fff",
      backgroundColor: palette.neutral.main
    }
  },
  outlinedPrimary: {
    "&:hover": {
      color: "#fff",
      backgroundColor: palette.neutral.main,
      borderColor: "#fff"
    }
  }
};
