/* eslint no-console: "off" */
let handler = function (name, level, args) {};

let consoleLog = function () {};

try {
  consoleLog = console.log;
} catch (ex) {
  // No console !!!
}

function safeStringifyVal(val) {
  JSON.stringify(val);
}

function safeStringify(args, level) {
  if (Array.isArray(args)) {
    return `[\r\n${args
      .map((v) => {
        return safeStringify(v, level - 1);
      })
      .join(", ")}\r\n]`;
    /*    } else if (typeof args === "object") {
        args
        return safeStringifyVal(args); */
  }
  try {
    return JSON.stringify(args);
  } catch (err) {
    return "ERR";
  }
}

/**
 * Pousse une entrée dans les logs
 * @param {string} name     nom du channel/module
 * @param {string} level    niveau de sévérité
 * @param {array} args      tableau des éléments devant être écrits
 * @returns {undefined}
 */
function log(name, level, args) {
  try {
    handler(name, level, safeStringify(args, 3));
    if (consoleLog) {
      consoleLog.apply(console, [name, level].concat(Array.prototype.slice.call(args)));
    }
  } catch (err) {
    // Failed to log data !!
  }
}

export default class Logger {
  protected name;

  public constructor(name) {
    this.name = name;
  }

  public debug(...args: any[]) {
    log(this.name, "DEBUG", args);
  }

  public log(...args: any[]) {
    log(this.name, "LOG", args);
  }

  public info(...args: any[]) {
    log(this.name, "INFO", args);
  }

  public warn(...args: any[]) {
    log(this.name, "WARN", args);
  }

  public warning(...args: any[]) {
    log(this.name, "WARN", args);
  }

  public error(...args: any[]) {
    log(this.name, "ERROR", args);
  }

  public exception(ex, level?) {
    log(this.name, level || "ERROR", [ex]);
  }

  public assert(ok, ...args: any[]) {
    if (!ok) {
      log(this.name, "ERROR", args);
    }
  }

  static DEBUG = "DEBUG";

  static INFO = "INFO";

  static WARN = "WARN";

  static ERROR = "ERROR";

  /**
   * Obtient une instance de logger qui va écrire dans le channel
   * @param {string} name     nom du channel ou écrire
   * @returns {Logger}
   */
  public static getLogger(name) {
    return new Logger(name);
  }

  public static get(name) {
    return new Logger(name);
  }

  /**
   * Attache un gesttionnaire logs
   * Permet de faire une action supllémentaire en plus de l'écriture dans la console
   * @param {function} value
   * @returns {undefined}
   */
  public static setHandler(value) {
    handler = value;
  }

  /**
   * Redirect console.log to this framework
   * */
  public static capstureConsole() {
    console.log = cLogger.log;
  }
}

const cLogger = new Logger("console");
