import { Preset, MediaTransform } from "@utils/data";

export const mediaPresets: Record<Preset, MediaTransform> = {
  cinematicItem: {
    type: "jpeg",
    width: 400,
    height: 225,
    method: "cover"
  },
  posterImage: {
    type: "jpeg",
    width: 900,
    height: 506,
    method: "cover"
  },
  collectableItem: {
    type: "png",
    width: 135,
    height: 135,
    method: "cover"
  },
  collectableAlt: {
    type: "png",
    width: 512,
    height: 512,
    method: "cover"
  },
  placeAlt: {
    type: "jpeg",
    width: 1920,
    height: 1080,
    method: "cover"
  },
  "360": {
    type: "png",
    width: 0,
    height: 0,
    method: "cover"
  },
  model: {
    type: "gltf",
    width: 0,
    height: 0,
    method: "cover"
  }
};
