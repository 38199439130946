// Le Contexte nous permet de transmettre une prop profondément dans l’arbre des
// composants sans la faire passer explicitement à travers tous les composants.

import React, { useContext } from "react";

interface LocalisationContextData {
  lang: string;
}

// Crée un contexte pour le thème (avec “light” comme valeur par défaut).
export const LocalisationContext = React.createContext<LocalisationContextData>({
  lang: "fr"
});

export const useLocalisedData = () => {
  return useContext(LocalisationContext);
};
