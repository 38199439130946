import React, { useContext } from "react";
import i18next from "i18next";

import { i18nOptions } from "@utils/i18n/i18n";
import { useRouteData } from "react-static";
import { useLocation } from "@reach/router";
import { LocalisationContext } from "@utils/i18n/localisation";

export const I18NContext = React.createContext(null);

const clients = {};

/**
 * Initialisation du module de langue global partagé
 */
const initTranslate = () => {
  i18next.init(
    {
      ...i18nOptions,
      resources: {
        fr: {},
        en: {}
      }
    },
    (err) => {
      if (err) {
        console.error("initTranslate failed", err);
      }
    }
  );
};

const addedLangs = {};
/**
 * Ajouter les ressources de la langue actuelle
 * Changer la langue actuelle
 * @param routeData
 * @param lang
 */
const configureTranslate = (routeData, lang) => {
  const trans = routeData[lang] || routeData.i18n;
  if (!addedLangs[lang] && trans?.translation) {
    addedLangs[lang] = true;
    i18next.addResourceBundle(lang, "translation", trans.translation, true, true);
  }
  if (i18next.language !== lang) {
    i18next.changeLanguage(lang);
  }
};

/**
 * Obtention d'une instance de langue utilisable dans react
 * @param routeData
 * @param lang
 * @returns
 */
const getI18nextClient = (routeData, lang) => {
  if (!clients[lang]) {
    const options = {
      ...i18nOptions,
      lng: lang,
      resources: {
        [lang]: routeData[lang] || routeData.i18n || {}
      }
    };
    clients[lang] = i18next.createInstance(options, (err) => {
      if (err) {
        console.error("createInstance failed", err);
      }
    });
  }

  return clients[lang];
};

initTranslate();

function getCookie(cname) {
  if (typeof document !== "undefined") {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
}

/**
 * Pour éviter les erreurs, il serait préférable d'utiliser
 * l'instance de i18n fournit par notre provider
 * @param param0
 * @returns
 */
export const I18nProvider = ({ children }) => {
  const routeData = useRouteData();
  const { pathname } = useLocation();
  const parts = pathname.split("/");
  let locale;
  if (typeof document !== "undefined") {
    const langAttr = (document.documentElement.getAttribute("lang") || "").substring(0, 2);
    const langCookie = getCookie("firebase-language-override");
    locale = routeData.lang || langCookie || langAttr || "fr"; // fetch past locale
  } else {
    locale = routeData.lang || "fr";
  }
  if (parts.length >= 2) {
    const l = parts[1];
    if (l === "en") {
      locale = "en";
    } else if (l === "fr") {
      locale = "fr";
    }
  }

  let i18n;
  if (locale) {
    configureTranslate(routeData, locale);
    i18n = getI18nextClient(routeData, locale);
  }
  return (
    <LocalisationContext.Provider value={{ lang: locale }}>
      <I18NContext.Provider value={i18n}>{children}</I18NContext.Provider>
    </LocalisationContext.Provider>
  );
};

export const useI18n = () => {
  return useContext(I18NContext);
};
