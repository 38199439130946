import { createContext } from "react";
import { TransitionState } from "./index.interface";

export const NavigationContext = createContext({
  transitionState: TransitionState.Static,
  goto: (path: string, state?: any): Promise<any> => {
    throw new Error("doit être overridé, assurez-vous d'avoir placé correctement le composant NavigationRoot");
  },
  incLoadingCounter: (): void => {
    throw new Error("doit être overridé, assurez-vous d'avoir placé correctement le composant NavigationRoot");
  },
  decLoadingCounter: (): void => {
    throw new Error("doit être overridé, assurez-vous d'avoir placé correctement le composant NavigationRoot");
  },
  instantEternalLoading: (): void => {}
});
