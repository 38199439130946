import { useLocation } from "@reach/router";
import { Referencable, ReferencableLang } from "@utils/data";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useRouteData, useSiteData } from "react-static";
import { useI18n } from "../../I18nextProvider";

import { WEBSITE_SITE_ROOT, GTM_KEY } from "../../utils/config";

export declare function gtag(...args): void;

function stripHtmlTags(input): string | null {
  if (input) {
    const nohtml = input
      .replace(/<[^>]*>/g, "")
      .replace(/\s+/g, " ")
      .trim()
      .substr(0, 200);
    const lastspace = nohtml.lastIndexOf(" ");
    if (lastspace > 0 && nohtml.length >= 195) {
      return nohtml.substr(0, lastspace);
    }
    return nohtml;
  }
  return null;
}

const Seo = () => {
  const i18n = useI18n();
  const routeData = useRouteData();
  const { lang, i18nRoot, item } = routeData;
  const itemL: ReferencableLang = item && item[lang] ? item[lang] : { title: null, description: null };
  const { pathname } = useLocation();
  const currentUrl = `${WEBSITE_SITE_ROOT}${pathname}`;
  const siteName = i18n.t("global.siteName");
  const title = stripHtmlTags(itemL.title || i18n.t(`${i18nRoot}.title`));
  const description = itemL.metaDescription || stripHtmlTags(itemL.description) || i18n.t(`${i18nRoot}.description`);

  return (
    <Helmet
      htmlAttributes={{
        lang: `${lang}-CA`
      }}
      title={title}
      titleTemplate={siteName ? `%s | ${siteName}` : null}
      meta={[
        {
          name: `description`,
          content: description
        },
        {
          name: `title`,
          content: `${title} | ${siteName}`
        }
      ]}
    >
      <meta name="title" content={`${title} | ${i18n.t("global.siteName")}`} />
      <meta name="description" content={description || i18n.t("global.metaDescription")} />
      <meta property="og:image" content={`${WEBSITE_SITE_ROOT}/souvenance-og-${lang}.webp`} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content={i18n.t("global.siteName")} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={`${title} | ${i18n.t("global.siteName")}`} />
      <link rel="canonical" href={currentUrl} />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="theme-color" content="#ffffff" />
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GTM_KEY}`} />
      <script type="text/javascript">
        {`
          <!-- Global site tag (gtag.js) - Google Analytics -->
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          var cookieState = null;
          try {
            if (typeof localStorage !== "undefined") {
              var s = localStorage.getItem("cc.cookie");
              if (s) {
                var p = JSON.parse(s);
                if (p && p.time > new Date().getTime() - 1000*3600*24 * 90) {
                  cookieState = p.state;
                }
              }
            }
          } catch (err) {
            console.error(err);
          }
          gtag('consent', 'default',  {
            'ad_storage': cookieState ? cookieState : 'denied',
            'analytics_storage': cookieState ? cookieState : 'denied'
          });
          gtag('js', new Date());
          gtag('config', '${GTM_KEY}');
        `}
      </script>
    </Helmet>
  );
};

export default Seo;
